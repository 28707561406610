<template>
  <div>
    <loader v-if="!error" class="m-auto"></loader>
    <div v-else style="max-width: 500px" class="hovering__pad p-4 mx-auto mt-5">
      <h3 class="mb-3">Ошибка! Попробуйте позднее</h3>
      <p>
        Или воспользуйтесь
        <b-button variant="link" href="https://u012655.stepform.io/Kt5D6gQ" target="_blank"
          >формой</b-button
        >
        и сообщите нам о проблеме, мы исправим её в кратчайшие сроки!
      </p>
    </div>
  </div>
</template>

<script>
import payments from '@main/api/payments';
import Loader from '../Loader.vue';

export default {
  data: () => ({
    error: false,
  }),
  mounted() {
    payments.sub
      .createPayment(this.$route.query)
      .then((res) => {
        if (res[0].PaymentURL) {
          window.location.href = res[0].PaymentURL;
        } else {
          this.error = true;
        }
      })
      .catch((e) => {
        this.error = true;
      });
  },
  components: { Loader },
};
</script>
